import React, { useEffect, useRef, useState } from 'react'
import CreatePurchases from './CreatePurchases'
import Button from '../../components/Button'
import { useNavigate } from 'react-router-dom'
import api, { customFuncsApi, logsUrl } from '../../api'
import { debounce, get } from 'lodash'
import _ from 'lodash'
import Layout from '../../components/Layout'
import { CreateUser, ErrorModal } from '../../components/Modal'
import ClipLoader from 'react-spinners/ClipLoader'
import { isAxiosError } from 'axios'
import moment from 'moment'
import { COMPANY_NAME } from '../../config'
import { useSelector } from 'react-redux'
import ConfirmModal from '../../components/ComfirmModal'

const CreatePurchasesPage = () => {
	const { getMe } = useSelector((state) => state.main)

	const succesCreateUserRef = useRef()
	const createUserRef = useRef()
	const navigation = useNavigate()
	const errorRef = useRef()
	const inputRef = useRef(null)
	const [businessPartnersData, setBusinessPartnersData] = useState([])
	const [itemsData, setItemsData] = useState([])
	const [selectedItem, setSelectedItem] = useState([])
	const [whichInput, setWhichInput] = useState(null)
	const [loadingBusinessPartners, setLoadingBusinessPartners] = useState(false)
	const [form, setForm] = useState({
		CardCode:
			get(getMe, 'Department2.Name', '') !== 'Sotuv_sherik'
				? ''
				: get(getMe, 'U_Supplier', ''),
		DocDate: moment().format('YYYY-MM-DD') + 'T00:00:00',
		DocCurrency: 'USD',
		DocumentLines: [],
	})
	const [createUserLoading, setCreateUserLoading] = useState(false)
	const initItem = {
		ItemCode: '',
		Quantity: 0,
		UnitPrice: 0,
		SerialNumbers: '',
		U_Primechaniye: '',
		U_IMEI_PURCH: 'IMEI',
	}
	const [items, setItems] = useState([
		{
			...initItem,
		},
	])
	const [itemsName, setItemsName] = useState([''])
	const [showModal, setShowModal] = useState(false)
	const [confirmationMessage, setConfirmationMessage] = useState('')
	const [btnLoading, setBtnLoading] = useState(false)

	const handleOpenModal = () => {
		const uniqueItems = []
		items.forEach((item) => {
			{
				uniqueItems.push({
					ItemCode: item.ItemCode,
					Quantity: 1,
					SerialNumbers: [{ InternalSerialNumber: item.SerialNumbers }],
					UnitPrice: item.UnitPrice,
					U_Primechaniye: item.U_Primechaniye,
					U_IMEI_PURCH: item.U_IMEI_PURCH,
				})
			}
		})

		setConfirmationMessage([
			{
				name: 'Дата:',
				value: form.DocDate,
			},
			{
				name: 'Код клиента :',
				value: form.CardCode,
			},
			{
				name: 'Курс:',
				value: form.DocCurrency,
			},
			...uniqueItems.flatMap((item) => [
				{
					name: 'Код товара:',
					value: item.ItemCode,
				},
				{
					name: 'Количество:',
					value: `${item.Quantity}`,
				},
				{
					name: 'Серия номер:',
					value: `${item.SerialNumbers[0].InternalSerialNumber}`,
				},
				{
					name: 'Цена за единицу товара:',
					value: `${item.UnitPrice}`,
				},
				{
					name: 'Комментарий:',
					value: `${item.U_Primechaniye ? item.U_Primechaniye : 'Нет'}`,
				},
				{
					name: 'IMEI:',
					value: `${item.U_IMEI_PURCH ? item.U_IMEI_PURCH : 'Нет'}`,
				},
			]),
		])
		setShowModal(true)
	}

	const getBusinessPartners = async (name = '') => {
		if (name.length <= 0) {
			setBusinessPartnersData([])
			return
		}
		setLoadingBusinessPartners(true)
		const data = await api(
			`BusinessPartners?$select=CardCode,CardName&$filter=CardType eq 'cSupplier' and Frozen eq 'tNO' and contains(CardName,'${name}') and Frozen eq 'tNO'`,
		).then((res) => {
			return JSON.parse(res.data).value
		})

		const selectElementData = data.map((item) => {
			return {
				value: item.CardCode,
				label: item.CardName,
			}
		})

		setBusinessPartnersData(selectElementData)
		setLoadingBusinessPartners(false)
	}

	const createUserF = (
		consumer,
		passport,
		male,
		address,
		phone,
		cardCode,
		typeCardCode,
	) => {
		setCreateUserLoading(true)
		api
			.post(`BusinessPartners`, {
				[typeCardCode ? 'CardCode' : 'Series']: cardCode,
				CardName: consumer,
				CardType: 'cSupplier',
				U_Telephone: phone.length ? phone : undefined,
				U_Gender: male,
				U_PS: passport.length ? passport : undefined,
				Currency: '##',
				U_address: address.length ? address : undefined,
			})
			.then(() => {
				try {
					logsUrl.post('apis', {
						company_name: COMPANY_NAME,
						method: 'post',
						path: `/BusinessPartners`,
						description: 'pokupkida business partner yaratish',
						username: `${getMe.FirstName} ${getMe.LastName}, EmployeeID: ${getMe.EmployeeID}`,
						body: JSON.stringify({
							CardCode: cardCode,
							CardName: consumer,
							CardType: 'cSupplier',
							U_Telephone: phone.length ? phone : undefined,
							U_Gender: male,
							U_PS: passport.length ? passport : undefined,
							Currency: '##',
							U_address: address.length ? address : undefined,
						}),
					})
				} catch (err) {
					errorRef.current?.open(
						'Bu jarayon logs tarixiga saqlanmadi. Iltimos bu haqida bizga habar bering!',
					)
				}
				createUserRef.current?.close()
				succesCreateUserRef.current?.open('Пользователь успешно создан.')
			})
			.catch((err) => {
				errorRef.current?.open(
					get(
						JSON.parse(err.response.data),
						'error.message',
						'Не удалось получить данные, попробуйте еще раз.',
					),
				)
			})
			.finally(() => {
				setCreateUserLoading(false)
			})
	}

	const purchaseInvoicesPost = async () => {
		const uniqueItems = []
		items.forEach((item) => {
			{
				uniqueItems.push({
					ItemCode: item.ItemCode,
					Quantity: 1,
					SerialNumbers: [{ InternalSerialNumber: item.SerialNumbers }],
					UnitPrice: item.UnitPrice,
					U_Primechaniye: item.U_Primechaniye,
					U_IMEI_PURCH: item.U_IMEI_PURCH,
					...(get(getMe, 'Department2.Name', '') !== 'Boshqaruv'
						? { WarehouseCode: get(getMe, 'U_Warehouse', '') }
						: {}),
				})
			}
		})
		const formatedDate = {
			...form,
			DocumentLines: uniqueItems,
		}

		setBtnLoading(true)

		try {
			await api
				.post(`PurchaseInvoices`, formatedDate)
				.then((res) => {
					return JSON.parse(res.data).value
				})
				.then((res) => {
					try {
						logsUrl.post('apis', {
							company_name: COMPANY_NAME,
							method: 'post',
							path: `/PurchaseInvoices`,
							description: 'pokupka yaratish',
							username: `${getMe.FirstName} ${getMe.LastName}, EmployeeID: ${getMe.EmployeeID}`,
							body: JSON.stringify(formatedDate),
						})
					} catch (err) {
						errorRef.current?.open(
							'Bu jarayon logs tarixiga saqlanmadi. Iltimos bu haqida bizga habar bering!',
						)
					}
				})
			navigation('/purchases')
		} catch (e) {
			if (isAxiosError(e)) {
				errorRef.current?.open(
					get(JSON.parse(e.response.data), 'error.message', ''),
				)
			}
		} finally {
			setBtnLoading(false)
		}
	}

	const getItems = async (name = '', i) => {
		if (name.length <= 0) {
			setItemsData([])
			return
		}
		setWhichInput(i)
		const data = await api(
			`Items?$select=ItemName,ItemCode,U_Condition&$filter=Frozen eq 'tNO' and contains(ItemName,'${name}')`,
		).then((res) => {
			return JSON.parse(res.data).value
		})

		setItemsData(data)
	}

	useEffect(() => {
		getBusinessPartners()
	}, [])

	const handleBusinessPartner = _.debounce((e) => {
		getBusinessPartners(e.target.value)
	}, 1000)

	const handleItem = _.debounce((e, i) => {
		getItems(e.target.value, i)
	}, 1000)

	useEffect(() => {
		setForm((prev) => ({ ...prev, DocumentLines: selectedItem }))
	}, [selectedItem])

	const handlePostBtnClick = () => {
		purchaseInvoicesPost()
	}

	const handleStartDateChange = (event) => {
		setForm((prev) => ({
			...prev,
			DocDate: moment(event.target.value).format('YYYY-MM-DD') + 'T00:00:00',
		}))
	}

	const handleCurrencyChange = (event) => {
		setForm((prev) => ({ ...prev, DocCurrency: event.target.value }))
	}

	const handleBusinessPartnerClick = (event, label, value) => {
		if (inputRef.current) {
			inputRef.current.value = event.target.innerText
		}
		setForm((prev) => ({
			...prev,
			CardCode: value,
		}))
		setBusinessPartnersData([])
	}

	const handleAddItem = () => {
		setItems((prev) => [...prev, { ...initItem }])
		setItemsName((prev) => [...prev, ''])
	}

	const handleRemoveItem = (i) => {
		setItems((prev) => prev.filter((_, idx) => idx !== i))
		setItemsData([])
		setItemsName((prev) => {
			const newItemsName = [...prev]
			newItemsName.splice(i, 1)
			return newItemsName
		})
	}

	const handleClickItem = (code, i) => {
		setItems((prev) => [
			...prev.map((item, idx) => {
				if (idx === i) {
					return {
						...item,
						ItemCode: code,
						UnitPrice: items.find((v) => v.ItemCode === code)?.UnitPrice,
					}
				}
				return item
			}),
		])
		setItemsName((prev) => {
			const newArray = [...prev]
			newArray[i] = itemsData.find((v) => v.ItemCode === code).ItemName
			return newArray
		})
		setItemsData([])
	}

	const handleConditionChange = (event, i) => {
		setItems((prev) => [
			...prev.map((item, idx) => {
				if (idx === i) {
					return {
						...item,
						Condition: event.target.value,
					}
				}
				return item
			}),
		])
	}

	const handleImeiPurch = (value, i) => {
		setItems((prev) => [
			...prev.map((item, idx) => {
				if (idx === i) {
					return {
						...item,
						U_IMEI_PURCH: value,
					}
				}
				return item
			}),
		])
	}
	const handleSerialNumberChange = (event, i) => {
		setItems((prev) => [
			...prev.map((item, idx) => {
				if (idx === i) {
					return {
						...item,
						SerialNumbers: event.target.value,
					}
				}
				return item
			}),
		])
	}

	const handlePrimechaniyeChange = (event, i) => {
		setItems((prev) => [
			...prev.map((item, idx) => {
				if (idx === i) {
					return {
						...item,
						U_Primechaniye: event.target.value,
					}
				}
				return item
			}),
		])
	}

	const handlePriceChange = (event, i) => {
		setItems((prev) => [
			...prev.map((item, idx) => {
				if (idx === i) {
					return {
						...item,
						UnitPrice: event.target.value,
					}
				}
				return item
			}),
		])
	}

	return (
		<Layout>
			<CreatePurchases>
				<div className="container">
					<div className="flex gap-4 items-start">
						{get(getMe, 'Department2.Name', '') !== 'Sotuv_sherik' ? (
							<div className="flex gap-2 items-start">
								<div className="searchable-select">
									<div className="relative">
										<input
											className={
												'border-[1px] border-[#DFE2E9"] p-1 rounded-md pl-4'
											}
											ref={inputRef}
											type="text"
											onChange={handleBusinessPartner}
											placeholder="Бизнес партнер"
										/>
										{loadingBusinessPartners && (
											<ClipLoader
												size={10}
												className="absolute right-2 top-3"
											/>
										)}
									</div>
									{!!businessPartnersData.length && (
										<div className="searchable-select-list">
											{businessPartnersData.map((item) => {
												return (
													<div
														key={item.value}
														className="searchable-select-item"
														onClick={(e) =>
															handleBusinessPartnerClick(
																e,
																item.label,
																item.value,
															)
														}
													>
														{item.label}, {item.value}
													</div>
												)
											})}
										</div>
									)}
								</div>
								<button
									className="bg-[#0A4D68] text-white px-4 rounded-md py-1"
									onClick={() => createUserRef.current?.open()}
								>
									+
								</button>
							</div>
						) : (
							''
						)}

						<input
							className={'border-[1px] border-[#DFE2E9"] p-1 rounded-md'}
							type="date"
							value={moment(form.DocDate).format('YYYY-MM-DD')}
							onChange={handleStartDateChange}
						/>
						<div className="flex flex-col">
							<select
								className={
									'border-[1px] border-[#DFE2E9"] p-1 rounded-md w-full flex-1'
								}
								onChange={handleCurrencyChange}
							>
								<option>USD</option>
								<option>UZS</option>
							</select>
						</div>
					</div>
					<div className="overflow-y-auto my-8">
						<table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
							<thead className="text-xs text-gray-700 uppercase bg-gray-50 "></thead>
							<tbody>
								{items.map((v, i) => {
									return (
										<tr className="bg-white  hover:bg-gray-50 " key={i}>
											<td className="px-6 py-4 border">
												<div className="searchable-select">
													<div className={'searchableHead'}>
														<div>
															<input
																className={
																	'border-[1px] border-[#DFE2E9"] p-1 rounded-md'
																}
																type="text"
																onChange={(event) => {
																	handleItem(event, i)
																	setItemsName((prev) => {
																		const newArray = [...prev]
																		newArray[i] = event.target.value
																		return newArray
																	})
																}}
																placeholder="Наименование товара"
																value={itemsName[i]}
															/>
															{!!itemsData.length && i === whichInput && (
																<div className="searchable-select-list">
																	{itemsData.map((item) => {
																		return (
																			<div
																				onClick={() =>
																					handleClickItem(item.ItemCode, i)
																				}
																				key={item.ItemCode}
																				className="searchable-select-item"
																			>
																				{item.ItemName}
																			</div>
																		)
																	})}
																</div>
															)}
														</div>
													</div>
												</div>
											</td>
											<td className="px-6 py-4 border">
												<input
													disabled={true}
													className={
														'border-[1px] border-[#DFE2E9"] p-1 rounded-md cursor-not-allowed'
													}
													value={
														itemsData.find((v) => v.ItemCode === v.ItemCode)
															?.U_Condition
													}
												></input>
											</td>
											<td className="px-6 border py-4">
												<select
													className={
														'border-[1px] border-[#DFE2E9"] p-1 rounded-md'
													}
													onChange={(event) =>
														handleImeiPurch(event.target.value, i)
													}
													value={v.U_IMEI_PURCH}
												>
													<option value="IMEI">С имеи</option>
													<option value="bez">Без</option>
												</select>
											</td>
											<td className="px-6 border py-4">
												<input
													placeholder="ИМЕИ"
													className={
														'border-[1px] border-[#DFE2E9"] p-1 rounded-md'
													}
													value={v.SerialNumbers}
													onChange={(event) => {
														handleSerialNumberChange(event, i)
													}}
												/>
											</td>
											<td className="px-6 border py-4">
												<input
													placeholder="Комментарий"
													className={
														'border-[1px] border-[#DFE2E9"] p-1 rounded-md'
													}
													value={v.U_Primechaniye}
													onChange={(event) => {
														handlePrimechaniyeChange(event, i)
													}}
												/>
											</td>
											<td className="px-6 border py-4">
												<input
													className={
														'border-[1px] border-[#DFE2E9"] p-1 rounded-md'
													}
													type="number"
													placeholder="Цена"
													onChange={(event) => {
														handlePriceChange(event, i)
													}}
													value={v.UnitPrice}
												/>
											</td>
											{i === items.length - 1 ? (
												<button
													onClick={handleAddItem}
													className="p-4 bg-[#0A4D68] text-white rounded-md ml-2"
												>
													+
												</button>
											) : null}
											{items.length > 1 ? (
												<button
													onClick={() => handleRemoveItem(i)}
													className="p-4 bg-red-600 text-white rounded-md ml-2"
												>
													-
												</button>
											) : null}
										</tr>
									)
								})}
							</tbody>
						</table>
					</div>

					<div
						style={{
							display: 'flex',
							justifyContent: 'end',
							paddingRight: '50px',
							paddingTop: '30px',
						}}
					>
						<Button isLoading={btnLoading} onClick={handleOpenModal}>
							Создать
						</Button>
					</div>
				</div>
			</CreatePurchases>
			<>
				<CreateUser
					isLoading={createUserLoading}
					getRef={(r) => (createUserRef.current = r)}
					onConfirm={(
						consumer,
						passport,
						male,
						address,
						phone,
						cardCode,
						typeCardCode,
					) =>
						createUserF(
							consumer,
							passport,
							male,
							address,
							phone,
							cardCode,
							typeCardCode,
						)
					}
				/>
				<ErrorModal
					getRef={(r) => {
						errorRef.current = r
					}}
				/>
			</>
			<ConfirmModal
				show={showModal}
				data={confirmationMessage}
				onConfirm={() => {
					setShowModal(false)
					handlePostBtnClick()
				}}
				onCancel={() => {
					setShowModal(false)
				}}
			/>
		</Layout>
	)
}

export default CreatePurchasesPage
