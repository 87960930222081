import React, { useEffect, useRef, useState } from 'react'
import DashboardStyle from './Dashboard'
import Button from '../../components/Button'
import { useNavigate } from 'react-router-dom'
import api, { customFuncsApi } from '../../api'
import { get } from 'lodash'
import Layout from '../../components/Layout'
import { ErrorModal } from '../../components/Modal'
import ClipLoader from 'react-spinners/ClipLoader'
import moment from 'moment'
import { useSelector } from 'react-redux'

const Dashboard = () => {
	const navigation = useNavigate()
	const errorRef = useRef()
	const { getMe } = useSelector((state) => state.main)

	const [isLoading, setIsLoading] = useState({
		loadingDebitCredit: false,
		loadingPurchases: false,
		loadingSold: false,
	})
	const [startDate, setStartDate] = useState(
		new Date().toISOString().split('T')[0],
	)
	const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0])
	const [data, setData] = useState({
		data: [],
	})
	const [pageSold, setPageSold] = useState(0)
	const [pagePurchased, setPagePurchased] = useState(0)
	const [purchasedData, setPurchasedData] = useState({
		data: [],
		currentPage: 0,
	})
	const [dailyAccountStats, setDailyAccountStats] = useState([])
	const [balanceMoney, setBalanceMoney] = useState([])
	const [balanceLoading, setBalanceLoading] = useState(false)

	const fetchDailyAccountStats = async () => {
		setIsLoading((prev) => ({ ...prev, loadingDebitCredit: true }))
		const accountsArray = [
			getMe?.U_CardAccount !== null ? getMe?.U_CardAccount : '',
			getMe?.U_CashAccount !== null ? getMe?.U_CashAccount : '',
			getMe?.U_CashUzsAccount !== null ? getMe?.U_CashUzsAccount : '',
			getMe?.U_TerminalAccount !== null ? getMe?.U_TerminalAccount : '',
			getMe?.U_TransAccount !== null ? getMe?.U_TransAccount : '',
		].filter(Boolean)

		const accounts = accountsArray.length ? JSON.stringify(accountsArray) : ''

		customFuncsApi
			.get(
				`xsSql/getDebitCreditSum?date1='${startDate + ' 00:00:00.000Z'}'&date2='${endDate + ' 00:00:00.000Z'}'${get(getMe, 'Department2.Name', '') !== 'Boshqaruv' ? `&accounts=${encodeURIComponent(accounts)}` : ''}`,
			)
			.then((res) => {
				setDailyAccountStats(res.data.value[0])
			})
			.finally(() => {
				setIsLoading((prev) => ({ ...prev, loadingDebitCredit: false }))
			})
	}

	const BalanceMoney = async () => {
		setBalanceLoading(true)
		const conditions = [
			getMe?.U_CardAccount !== null ? `Code eq '${getMe.U_CardAccount}'` : '',
			getMe?.U_CashAccount !== null ? `Code eq '${getMe.U_CashAccount}'` : '',
			getMe?.U_CashUzsAccount !== null
				? `Code eq '${getMe.U_CashUzsAccount}'`
				: '',
			getMe?.U_TerminalAccount !== null
				? `Code eq '${getMe.U_TerminalAccount}'`
				: '',
			getMe?.U_TransAccount !== null ? `Code eq '${getMe.U_TransAccount}'` : '',
		].filter(Boolean)

		const filterCondition = conditions.length ? conditions.join(' or ') : ''
		const data = await api
			.get(
				`ChartOfAccounts?$filter=${filterCondition && get(getMe, 'Department2.Name', '') !== 'Boshqaruv' ? `${filterCondition} and ` : "startswith(Code, '50') and "}ExternalCode eq '1'&$select=Code,Name,Balance,Balance_syscurr,Balance_FrgnCurr,AcctCurrency`,
				{
					headers: {
						Prefer: 'odata.maxpagesize=10000',
					},
				},
			)
			.then((res) => JSON.parse(res.data).value)
		setBalanceMoney(data)
		setBalanceLoading(false)
	}

	const sold = () => {
		setIsLoading((prev) => ({ ...prev, loadingSold: true }))
		customFuncsApi(
			`xsSql/getLastInitialPayments?date1='${startDate + ' 00:00:00.000Z'}'&date2='${endDate + ' 00:00:00.000Z'}'&$skip=${pageSold}${get(getMe, 'Department2.Name', '') !== 'Boshqaruv' ? `&WhsCode='${getMe?.U_Warehouse}'` : ''}`,
			{
				headers: {
					Prefer: 'odata.maxpagesize=10',
				},
			},
		)
			.then((res) => {
				const resData = res.data.value
				setData({ data: [...resData] })
			})
			.catch((err) => {
				// errorRef.current?.open(
				//   get(JSON.parse(err.response.data), 'error.message', ''),
				// )
			})
			.finally(() => {
				setIsLoading((prev) => ({ ...prev, loadingSold: false }))
			})
	}

	const purchased = () => {
		setIsLoading((prev) => ({ ...prev, loadingPurchases: true }))
		customFuncsApi(
			`xsSql/getLastPurchases?date1='${startDate + ' 00:00:00.000Z'}'&date2='${endDate + ' 00:00:00.000Z'}'&$skip=${pagePurchased}${get(getMe, 'Department2.Name', '') !== 'Boshqaruv' ? `&WhsCode='${getMe?.U_Warehouse}'` : ''}`,
			{
				headers: {
					Prefer: 'odata.maxpagesize=10',
				},
			},
		)
			.then((res) => {
				const resData = res.data.value
				setPurchasedData({ data: [...resData] })
			})
			.catch((err) => {
				// errorRef.current?.open(
				//   get(JSON.parse(err.response.data), 'error.message', ''),
				// )
			})
			.finally(() => {
				setIsLoading((prev) => ({ ...prev, loadingPurchases: false }))
			})
	}

	useEffect(() => {
		BalanceMoney()
	}, [])

	useEffect(() => {
		fetchDailyAccountStats()
	}, [startDate, endDate])

	useEffect(() => {
		purchased()
	}, [startDate, endDate, pagePurchased])

	useEffect(() => {
		sold()
	}, [pageSold, startDate, endDate])

	const newDatas = (table) => {
		if (table === 'sold') {
			if (data.data.length < 10) {
				alert('boshqa malumot yoq')
			} else setPageSold((prev) => prev + 10)
		} else {
			if (purchasedData.data.length < 10) {
				alert('boshqa malumot yoq')
			} else setPagePurchased((prev) => prev + 10)
		}
	}

	const oldData = (table) => {
		if (table === 'sold' && pageSold <= 0) {
			alert('boshqa malumot yoq')
		} else if (table === 'purchased' && pagePurchased <= 0) {
			alert('boshqa malumot yoq')
		} else {
			table === 'sold'
				? setPageSold((prev) => prev - 10)
				: setPagePurchased((prev) => prev - 10)
		}
	}

	const handleStartDateChange = (event) => {
		setStartDate(event.target.value)
	}

	const handleEndDateChange = (event) => {
		setEndDate(event.target.value)
	}

	return (
		<Layout>
			<DashboardStyle>
				<div className="container">
					<p className="productTitle mb-10">Главная страница</p>
					<div className="flex gap-4">
						<div>
							<p className={'font-medium text-zinc-600 text-sm mb-1'}>
								Дата начала
							</p>
							<input
								className={'border-[1px] border-[#DFE2E9"] p-1 rounded-md'}
								type="date"
								value={startDate}
								onChange={handleStartDateChange}
							/>
						</div>
						<div>
							<p className={'font-medium text-zinc-600 text-sm mb-1'}>
								Дата окончания
							</p>
							<input
								className={'border-[1px] border-[#DFE2E9"] p-1 rounded-md '}
								type="date"
								value={endDate}
								onChange={handleEndDateChange}
							/>
						</div>
					</div>

					<div className="invoice-container ">
						{isLoading.loadingDebitCredit || balanceLoading ? (
							<ClipLoader
								loading={true}
								size={20}
								aria-label="Loading Spinner"
								className={'loader'}
								data-testid="loader"
							/>
						) : (
							<>
								<div className={'flex flex-wrap gap-4 items-start'}>
									<div className="invoice-card min-w-[300px]">
										<div className="type income w-fit">Сегодняшняя ввод</div>
										<p className="mt-4 text-xl font-bold text text-zinc-900">
											{(dailyAccountStats?.DebitSum &&
												new Intl.NumberFormat('fr-FR', {
													minimumFractionDigits: 2,
													maximumFractionDigits: 2,
												})
													.format(dailyAccountStats?.DebitSum)
													.replace(',', '.')) ||
												0}
											USD
										</p>
									</div>
									<div className="invoice-card min-w-[300px]">
										<div className="type withdraw w-fit">Сегодняшняя вывод</div>
										<p className="mt-4 text-xl font-bold text text-zinc-900">
											{(dailyAccountStats?.CreditSum &&
												new Intl.NumberFormat('fr-FR', {
													minimumFractionDigits: 2,
													maximumFractionDigits: 2,
												})
													.format(dailyAccountStats?.CreditSum)
													.replace(',', '.')) ||
												0}
											USD
										</p>
									</div>
									<div className="max-h-[30vh] overflow-y-scroll">
										<table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
											<thead className="text-xs text-gray-700 uppercase bg-gray-50  ">
												<th scope="col" className="px-6 py-4">
													Счет
												</th>
												<th scope="col" className="px-6 py-4">
													Название
												</th>
												<th scope="col" className="px-6 py-4">
													Баланс
												</th>
												<th scope="col" className="px-6 py-4">
													Валюта
												</th>
											</thead>
											<tbody>
												{balanceMoney.length ? (
													balanceMoney.map((v, i) => {
														return (
															<tr
																className="bg-white border-b  hover:bg-gray-50 "
																key={i}
															>
																<td
																	scope="row"
																	className="px-6 py-4 font-medium text-gray-900 "
																>
																	{v.Code}
																</td>
																<td
																	scope="row"
																	className="px-6 py-4 font-medium text-gray-900 "
																>
																	{v.Name}
																</td>
																<td
																	scope="row"
																	className="px-6 py-4 font-medium text-gray-900 "
																>
																	{v.AcctCurrency === 'UZS'
																		? v.Balance_FrgnCurr
																		: v.Balance}
																</td>
																<td
																	scope="row"
																	className="px-6 py-4 font-medium text-gray-900 "
																>
																	{v.AcctCurrency}
																</td>
															</tr>
														)
													})
												) : (
													<p className="px-6 py-4 font-medium text-gray-900 ">
														Счет нету
													</p>
												)}
											</tbody>
										</table>
									</div>
								</div>
							</>
						)}
					</div>

					<div>
						<h4 className={'font-bold text-lg text-center mt-10 mb-4'}>
							Продано
						</h4>
						{isLoading.loadingSold ? (
							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<ClipLoader
									loading={true}
									size={20}
									aria-label="Loading Spinner"
									className={'loader'}
									data-testid="loader"
								/>
							</div>
						) : (
							<div className="overflow-y-auto mb-8">
								<table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
									<thead className="text-xs text-gray-700 uppercase bg-gray-50  ">
										<tr>
											<th scope="col" className="px-6 py-4">
												Товар
											</th>
											<th scope="col" className="px-6 py-4">
												Дата продажи
											</th>
											<th scope="col" className="px-6 py-4">
												IMEI
											</th>
											<th scope="col" className="px-6 py-4">
												Взнос
											</th>
											<th scope="col" className="px-6 py-4">
												Итого
											</th>
										</tr>
									</thead>
									<tbody>
										{data.data.map((v, i) => {
											return (
												<tr
													key={i}
													className="bg-white border-b  hover:bg-gray-50 "
												>
													<td
														scope="row"
														className="px-6 py-4 font-medium text-gray-900 "
													>
														{v.Dscription}
													</td>
													<td className="px-6 py-4">
														{v.DocDate &&
															moment(v.DocDate).format('DD-MM-YYYY')}
													</td>{' '}
													<td className="px-6 py-4">{v.IntrSerial || '-'}</td>
													<td className="px-6 py-4">
														{v.SumApplied && Number(v.SumApplied).toFixed(2)}
													</td>
													<td className="px-6 py-4">
														{v.DocTotal && Number(v.DocTotal).toFixed(2)}
													</td>
												</tr>
											)
										})}
									</tbody>
								</table>
							</div>
						)}

						<div className="topCard">
							<div className="flex">
								<Button
									disabled={pageSold === 0}
									className={'btn'}
									onClick={() => oldData('sold')}
									btnStyle={{ marginRight: 10 }}
								>
									{'<'}
								</Button>
								<Button
									disabled={data.data.length < 10}
									className={'btn'}
									onClick={() => newDatas('sold')}
								>
									{'>'}
								</Button>
							</div>
						</div>
					</div>

					<div>
						<h4 className={'font-bold text-lg text-center mt-10 mb-4'}>
							Куплено
						</h4>
						{isLoading.loadingPurchases ? (
							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<ClipLoader
									loading={true}
									size={20}
									aria-label="Loading Spinner"
									className={'loader'}
									data-testid="loader"
								/>
							</div>
						) : (
							<div className="overflow-y-auto mb-8">
								<table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
									<thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
										<tr>
											<th scope="col" className="px-6 py-4">
												Товар
											</th>
											<th scope="col" className="px-6 py-4">
												Дата покупки
											</th>
											<th scope="col" className="px-6 py-4">
												IMEI
											</th>
											<th scope="col" className="px-6 py-4">
												Итого
											</th>
											<th scope="col" className="px-6 py-4">
												Покупатель
											</th>
											<th scope="col" className="px-6 py-4">
												Количество
											</th>
										</tr>
									</thead>
									<tbody>
										{purchasedData.data.map((v, i) => {
											return (
												<tr
													key={i}
													className="bg-white border-b  hover:bg-gray-50 "
												>
													<td
														scope="row"
														className="px-6 py-4 font-medium text-gray-900 "
													>
														{v.Dscription}
													</td>
													<td className="px-6 py-4">
														{v.DocDate &&
															moment(v.DocDate).format('DD-MM-YYYY')}
													</td>
													<td className="px-6 py-4">{v.IntrSerial || '-'}</td>
													<td className="px-6 py-4">
														{v.DocTotal && Number(v.DocTotal).toFixed(2)}
													</td>
													<td className="px-6 py-4">{v.CardName}</td>
													<td className="px-6 py-4">
														{Math.round(v.Quantity)}
													</td>
												</tr>
											)
										})}
									</tbody>
								</table>
							</div>
						)}

						<div className="topCard">
							<div className="flex">
								<Button
									disabled={pagePurchased === 0}
									className={'btn'}
									onClick={() => oldData('purchased')}
									btnStyle={{ marginRight: 10 }}
								>
									{'<'}
								</Button>
								<Button
									disabled={purchasedData.data.length < 10}
									className={'btn'}
									onClick={() => newDatas('purchased')}
								>
									{'>'}
								</Button>
							</div>
						</div>
					</div>
				</div>
			</DashboardStyle>
			<>
				<ErrorModal
					getRef={(r) => {
						errorRef.current = r
					}}
				/>
			</>
		</Layout>
	)
}

export default Dashboard
